var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes,style:(this.styles),attrs:{"id":_vm.modalId,"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Preview")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[(_vm.data)?_c('div',[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[(_vm.form.template.body)?_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"sms-body"}},[_vm._v("Preview")])]):_vm._e(),(_vm.form.template.body)?_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"whats-container"},[_c('div',{staticClass:"whats-preview"},[_c('div',{staticClass:"relative"},[(
                          _vm.form.template.header &&
                          _vm.form.template.header.format === 'IMAGE'
                        )?_c('img',{attrs:{"id":"img-preview","src":("" + (_vm.form.template.header.example.header_handle[0]))}}):_vm._e()]),(
                        _vm.form.template.header &&
                        _vm.form.template.header.format === 'TEXT'
                      )?_c('p',{staticClass:"whats-preview-header"},[_vm._v(" "+_vm._s(_vm.form.template.header.text)+" ")]):_vm._e(),_c('p',{staticClass:"whats-preview-body"},[_vm._v(" "+_vm._s(_vm.form.template.body.text)+" ")]),(_vm.form.template.footer)?_c('p',{staticClass:"whats-preview-footer"},[_vm._v(" "+_vm._s(_vm.form.template.footer.text)+" ")]):_vm._e()]),(_vm.form.template.buttons)?_c('div',{staticClass:"row"},[(_vm.form.template.buttons.type == 'CALL_TO_ACTION')?_c('div',{staticClass:"col-sm-12"},[(_vm.form.template.buttons.call_to_action_dial_phone)?_c('button',{staticClass:"btn-link-preview",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-phone"}),_vm._v(" "+_vm._s(_vm.form.template.buttons.call_to_action_dial_phone.text)+" ")]):_vm._e(),(_vm.form.template.buttons.call_to_action_website)?_c('button',{staticClass:"btn-link-preview",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-link"}),_vm._v(" "+_vm._s(_vm.form.template.buttons.call_to_action_website.text)+" ")]):_vm._e()]):_vm._e(),(_vm.form.template.buttons.quick_replies.length > 0)?_c('div',_vm._l((_vm.form.template.buttons
                          .quick_replies),function(btn,i){return _c('div',{key:i,staticClass:"col-sm-12"},[_c('button',{staticClass:"btn-link-preview",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(btn)+" ")])])}),0):_vm._e()]):_vm._e()])]):_vm._e()])])]):_vm._e()]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" "+_vm._s(_vm.$t('generic-str.close'))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }